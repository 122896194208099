export const contact = {
  number: '0736175471',
  numberEU: '+46736175471',
  email: 'info@aasztokholm.se',
  time: "18:00 - 22.00",
}

export const contact2 = {
  number: '0762182567',
  numberEU: '+46762182567',
  email: 'info@aasztokholm.se',
  time: "18:00 - 22.00",
}